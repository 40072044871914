import { lazy } from "react";

const Home = lazy(() => import("../../pages/home/Home"));
const Admissions = lazy(() => import("../../pages/admissions/Admissions"));
const About = lazy(() => import("../../pages/about/About"));
const ParentsStudents = lazy(() =>
  import("../../pages/parents-students/ParentsStudents")
);
const News = lazy(() => import("../../pages/news/News"));

export { Home, Admissions, About, ParentsStudents, News };
