import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "../../styles/scss/global/global.scss";
import * as Global from "./global.router";
import * as Pages from "./pages.router";
import * as Misc from "./misc.router";

const { Images } = Misc;
const { Layout, Loading, NoPage } = Global;
const { Home, Admissions, About, ParentsStudents, News } = Pages;

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          <Route path="admissions">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <Admissions />
                </Suspense>
              }
            />
          </Route>
          <Route path="about">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <About />
                </Suspense>
              }
            />
          </Route>
          <Route path="parents-students">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <ParentsStudents />
                </Suspense>
              }
            />
          </Route>
          <Route path="news">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <News />
                </Suspense>
              }
            />
          </Route>
          <Route path="dev">
            <Route path="preview">
              <Route path="loading" element={<Loading />} />
            </Route>
          </Route>
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="copyright">
          <Route path="credit">
            <Route path="images" element={<Images />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
